import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { api_admin_link_v2 } from '../services/apis';

const baseUrl = api_admin_link_v2;


const retrieveStoredRealState = () => {
    const storedImobiliaria = localStorage.getItem('imobiliaria');
    let initialRealState = null;
    if (storedImobiliaria) {
        initialRealState = storedImobiliaria;
    }
    return {
        imobiliaria: initialRealState,
    };
};

export const requestUsersAccessList = createAsyncThunk(
    'users/requestUsersAccessList',
    async (dadosUsuario) => {
        const response = await fetch(`${baseUrl}users/allow/access/list/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ usuario_id: dadosUsuario.id, dadosUsuario })
        });
        const data = await response.json();
        return data.accessList;
    }
);

const initialImobiliariaState = {
    imobiliaria: retrieveStoredRealState().imobiliaria,
    imobiliarias: [],

};


const verifyImobiliaria = () => {
    if (
        localStorage.getItem('imobiliaria') == '' ||
        localStorage.getItem('imobiliaria') == null ||
        localStorage.getItem('imobiliaria') == undefined
    ) {
        return false;
    } else {
        return true;
    }
};

const occupationImobiliariaSlice = createSlice({
    name: 'imobiliaria',
    initialState: initialImobiliariaState,
    reducers: {
        setToken(state, action) {
            localStorage.setItem('imobiliaria', action.payload.imobiliaria);
            state.imobiliaria = action.payload.imobiliaria;
        },
        clearImobiliaria(state) {
            localStorage.removeItem('imobiliaria');
            state.imobiliaria = null; 
          },
    },
    extraReducers: (builder) => {
        builder.addCase(
            requestUsersAccessList.fulfilled,
            (state, action) => {
                state.imobiliarias = action.payload;
            },
        );
    },
});

export const occupationImobiliariaActions = occupationImobiliariaSlice.actions;
export const selectImobiliaria = (state) => state.occupationImobiliaria.imobiliaria;
export const imobiliaria = (state) => state.occupationImobiliaria.imobiliaria;
export const hasImobiliaria = () => verifyImobiliaria();
export const selectImobiliarias = (state) => state.occupationImobiliaria.imobiliarias; 

export default occupationImobiliariaSlice.reducer;
