import React, { useState, useEffect } from 'react';
import { Typography, Divider, Box, Button, Autocomplete, TextField } from '@mui/material';
import { useAppDispatch } from '../hooks/redux-hooks.jsx';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import MiniCard from '../components/MiniCard.jsx';
import { occupationImobiliariaActions, selectImobiliarias } from '../redux/real-state-slice.jsx';

const SelecionarImobiliaria = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [selectedImobiliaria, setSelectedImobiliaria] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const imobiliarias = useSelector(selectImobiliarias);
  const token = localStorage.getItem('autenticado_freedom');

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token, history]);

  useEffect(() => {
    setIsFormValid(!!selectedImobiliaria);
  }, [selectedImobiliaria]);

  const updateToken = (newImobiliariaId, newPermissaoTipo, newUrlLogo, newRazaoSocial) => {
    return new Promise(async (resolve, reject) => {
      if (token) {
        const decoded = jwt_decode(token);
        const newToken = jwt.sign({
          imobiliaria_id: newImobiliariaId,
          id: decoded.id,
          permissao_tipo: newPermissaoTipo,
          imobiliaria: newRazaoSocial,
          urlLogo: newUrlLogo,
          permissao: decoded.permissao,
          nome: decoded.nome,
          iat: decoded.iat,
          exp: decoded.exp,
          email: decoded.email
        }, 'bigode');
  
        localStorage.setItem('autenticado_freedom', newToken);
        resolve(newToken); 
      } else {
        reject('Token não encontrado');
      }
    });
  };
  const onClickHandler = async () => {
    try {
      if (selectedImobiliaria && selectedImobiliaria.imobiliaria_id) {
        const { imobiliaria_id, user_access_permissao_tipo, urlLogo, imobiliaria_nome } = selectedImobiliaria;
  
        await updateToken(imobiliaria_id, user_access_permissao_tipo, urlLogo, imobiliaria_nome);
  
        dispatch(occupationImobiliariaActions.setToken({ imobiliaria: imobiliaria_id }));
        history.push('/freedom/contratos');
      }
    } catch (error) {
      console.error("Erro ao atualizar o token:", error);
    }
  };
  

  return (
    <MiniCard sx={{ maxWidth: 600, padding: 4 }}>
      <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
        Seja bem-vindo ao Freedom!
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography fontSize={16} variant="body1" color="gray" sx={{ mb: 2 }}>
        Por favor, escolha sua imobiliária:
      </Typography>
      <Box display="flex" flexDirection="column" gap={4}>
        <Autocomplete
          id="imobiliaria-autocomplete"
          options={imobiliarias || []}
          getOptionLabel={(option) => option.imobiliaria_nome}
          value={selectedImobiliaria}
          onChange={(event, newValue) => {
            setSelectedImobiliaria(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Imobiliária"
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiFormLabel-root': {
                  fontSize: 10,
                },
              }}
            />
          )}
        />
        <Button
          onClick={onClickHandler}
          disabled={!isFormValid}
          variant="contained"
          sx={{ fontSize: 18, padding: '12px 24px' }}
        >
          Começar!
        </Button>
      </Box>
    </MiniCard>
  );
};

export default SelecionarImobiliaria;
