import React, { useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import menuItens from "./menuItens";
import Element from "./Element";

function Dropdown(props) {
   const [display, setDisplay] = useState('none');

   function handleClick() {
      if (display === 'none') {
         setDisplay('')
      } else {
         setDisplay('none')
      }
   }

   function disable_button() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read"
   }

   function disable_button_master() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"
   }

   function disable_button_write_and_master() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo  === "ReadWriteMaster" || "ReadWrite" ? false : true
   }

   if (Object.keys(menuItens[props.titleId - 1].contentTitle).length === 0) {
      if (props.titleId === 1) {
         return (
            <Link className="nav--item" to="/freedom/dashboard">
               <li id={window.location.pathname.startsWith('/freedom/dashboard') ? 'nav--active': ''}>
                  <i className="fas fa-chart-line"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
            </Link>
         )
      } else if (props.titleId === 4) {
         return (
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/epbank">
               <li id={window.location.pathname.startsWith('/freedom/epbank') ? 'nav--active': ''}>
                  <i className="fas fa-random"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
            </Link>
         )
      } else {
         return (
            <Link className="nav--item" to="/freedom/dimob">
               <li id={window.location.pathname.startsWith('/freedom/dimob') ? 'nav--active': ''}>
                  <i className="fas fa-archway"></i>
                  <p className="nav--item__p">Dimob</p>
               </li>
            </Link>
         )
      }
   } else {
      if (props.titleId === 2) {
         return (
            <Link className="nav--item" onClick={(e) => {e.preventDefault()}}>
               <li onClick={handleClick}>
                  <i className="fas fa-file-signature"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
               <div style={{display: display}}>
                  <Element titleId={2} />
               </div>
            </Link>
         )
      } else if (props.titleId === 3) {
         return (
            <Link className="nav--item" onClick={(e) => {e.preventDefault()}}>
               <li onClick={handleClick}>
                  <i className="fas fa-dollar-sign"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
               <div style={{display: display}}>
                  <Element titleId={3} />
               </div>
            </Link>
         )
      } else if (props.titleId === 5) {
         return (
            <Link className="nav--item" onClick={(e) => {e.preventDefault()}}>
               <li onClick={handleClick}>
                  <i className="fas fa-calendar-week"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
               <div style={{display: display}}>
                  <Element titleId={5} />
               </div>
            </Link>
         )
      } else if (props.titleId === 6) {
         return (
            <Link className="nav--item" onClick={(e) => {e.preventDefault()}}>
               <li onClick={handleClick}>
                  <i className="fas fa-book"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
               <div style={{display: display}}>
                  <Element titleId={6} />
               </div>
            </Link>
         )
      } else {
         return (
            <Link className="nav--item" onClick={(e) => {e.preventDefault()}}>
               <li onClick={handleClick}>
                  <i className="fas fa-book"></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].title}</p>
               </li>
               <div style={{display: display}}>
                  <Element titleId={7} />
               </div>
            </Link>
         )
      }
   }
}

export default Dropdown;