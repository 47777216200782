
import Login from "./views/Login.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Contratos from "./views/Contratos.jsx";
import Contrato from "./views/Contrato.jsx";
import Parcelas from "./views/Parcelas.jsx";
import Servicos from "./views/Servicos.jsx";
import DetalheParcela from "./views/DetalheParcela.jsx";
import GerarBoletos from "./views/GerarBoletos.jsx";
import DemonstrativoPagamento from "./views/DemonstrativoPagamento.jsx";
import GerarRepasses from "./views/GerarRepasses.jsx";
import AlterarBoleto from "./views/AlterarBoleto.jsx";
import Pessoas from "./views/Pessoas.jsx";
import DemonstrativoLocador from "./views/DemonstrativoLocadorOld.jsx";
import AlterarParcela from "./views/AlterarParcela.jsx";
import CadastrarContrato from "./views/CadastrarContrato.jsx";
import AlterarContrato from "./views/AlterarContrato.jsx";
import GerarTransferencias from "./views/GerarTransferencias.jsx";
import RelatorioInadimplencia from "./views/RelatorioInadimplencia.jsx";
import RelatorioInadimplenciaPdf from "./views/RelatorioInadimplentesPdf.jsx";
import RelatorioContratosPdf from "./views/RelatorioContratos.jsx";
import RelatorioSeguroPdf from "./views/RelatorioSeguroPdf.jsx";
import RelatorioExtratoImob from "./views/RelatorioExtratoImob";
import ContratosAvencer from "./views/ContatosAvencer.jsx";
import RelatorioTransferenciaPage from "./views/RelatorioTransferencia.jsx";
import RelatorioGarantiasPage from "./views/RelatorioGarantias.jsx";
import RelatorioRepassePage from "./views/RelatorioRepasse.jsx";
import RelatorioRepassePdf from "./views/RelatorioRepassePdf.jsx";
import RelatorioRescisaoPage from "./views/RelatorioRescisao.jsx";
import RelatorioRescisaoPdf from "./views/RelatorioRescisaoPdf.jsx";
import RelatorioAtivosPage from "./views/RelatorioAtivos.jsx";
import RelatorioAtivosPdf from "./views/RelatorioAtivosPdf.jsx";
import ImprimirRelatorioRepassePage from "./views/ImprimirRelatorioRepasse.jsx";
import ModelosCreatePage from "./views/ModelosCreate.jsx";
import ModelosListPage from "./views/ModelosList.jsx";
import ModelosEditPage from "./views/ModeloEdit.jsx";
import ImprimirModeloContratoPage from "./views/impressoes/ImprimirModeloContrato.jsx";
import ExtratoImobPage from "./views/ExtratoImob.jsx";
import ContratosImportadosPage from "./views/ConstratosImportados.jsx";
import IndicesPage from "./views/Indices.jsx";
import DimobPage from "./views/Dimob.jsx";
import DemonstrativoDimob from "./views/DemonstrativoDimob.jsx";
import IndiceValoresPage from "./views/IndiceValores.jsx";
import ContratosReajustesPage from "./views/ContratosRajustes.jsx";
import RelatorioSeguroPage from "./views/RelatorioSeguro.jsx";
import LogsPage from "./views/Logs.jsx";
import Nfse from "./views/Nfse.jsx";
import MunicipiosDimob from "./views/MunicipiosDimob.jsx";
import RepasseGarantido from "./views/RepasseGarantido.jsx";
import HistoricoRepasseGarantido from "./views/HistoricoRepasseGarantido.jsx";
import RelatorioGarantiasPdf from "./views/RelatorioGarantiasPdf.jsx";
import DetalheCobranca from "./views/DetalheCobranca.jsx";
import DemonstrativoCobranca from "./views/DemonstrativoCobranca.jsx";
import Cobrancas from "./views/Cobranças.jsx";
import DemonstrativoLocadorCobranca from "./views/DemonstrativoLocadorCobranca.jsx";

var routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    layout: "/"
  },

  {
    path: "/imprimirModeloContrato/:id/:modelo",
    name: "Imprimir modelo de contrato",
    component: ImprimirModeloContratoPage,
    layout: "/imprimir"
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/freedom"
  },

  {
    path: "/imobstatement",
    name: "Extrato da imobiliária",
    component: ExtratoImobPage,
    layout: "/freedom"
  },

  {
    path: "/dimob",
    name: "DIMOB",
    component: DimobPage,
    layout: "/freedom"
  },

  {
    path: "/demonstrativodimob/:identificador",
    name: "Demonstrativo Dimob",
    component: DemonstrativoDimob,
    layout: "/freedom"
  },

  {
    path: "/municipiosdimob",
    name: "Municípios Dimob",
    component: MunicipiosDimob,
    layout: "/freedom"
  },

  {
    path: "/indices",
    name: "Indices de reajuste",
    component: IndicesPage,
    layout: "/freedom"
  },

  {
    path: "/indice/:id/valores",
    name: "Valores de reajuste",
    component: IndiceValoresPage,
    layout: "/freedom"
  },

  {
    path: "/reajustecontratos",
    name: "Reajuste de contratos",
    component: ContratosReajustesPage,
    layout: "/freedom"
  },

  {
    path: "/contratos",
    name: "Contratos",
    component: Contratos,
    layout: "/freedom"
  },

  {
    path: "/modeloscreate",
    name: "Modelos",
    component: ModelosCreatePage,
    layout: "/freedom"
  },

  {
    path: "/modelosedit/:id",
    name: "Modelos",
    component: ModelosEditPage,
    layout: "/freedom"
  },

  {
    path: "/modelos",
    name: "Modelos",
    component: ModelosListPage,
    layout: "/freedom"
  },

  {
    path: "/contratosCorrecao",
    name: "Contratos Correcão",
    component: ContratosAvencer,
    layout: "/freedom"
  },

  {
    path: "/contrato/:id",
    name: "Detalhes do contrato",
    component: Contrato,
    layout: "/freedom"
  },

  {
    path: "/parcelas/:id",
    name: "Parcelas do contrato",
    component: Parcelas,
    layout: "/freedom"
  },

  {
    path: "/cobrancas/:id",
    name: "Cobranças do Contrato",
    component: Cobrancas,
    layout: "/freedom"
  },

  {
    path: "/servicos",
    name: "Serviços",
    component: Servicos,
    layout: "/freedom"
  },

  {
    path: "/detalheparcela/:contrato_id/:parcela_id",
    name: "Detalhes da parcela",
    component: DetalheParcela,
    layout: "/freedom"
  },

  {
    path: "/detalhecobranca/:contrato_id/:parcela_id",
    name: "Detalhes da cobrança",
    component: DetalheCobranca,
    layout: "/freedom"
  },

  {
    path: "/gerarboletos",
    name: "Gerar boletos",
    component: GerarBoletos,
    layout: "/freedom"
  },

  {
    path: "/logs",
    name: "Logs do sitema",
    component: LogsPage,
    layout: "/freedom"
  },

  {
    path: "/demonstrativopagamento/:identificador",
    name: "Demonstrativo do Pagamento",
    component: DemonstrativoPagamento,
    layout: "/freedom"
  },
  {
    path: "/demonstrativocobranca/:identificador",
    name: "Demonstrativo do Pagamento",
    component: DemonstrativoCobranca,
    layout: "/freedom"
  },

  {
    path: "/importados",
    name: "Contratos importados",
    component: ContratosImportadosPage,
    layout: "/freedom"
  },

  {
    path: "/gerarrepasses",
    name: "Gerar repasses",
    component: GerarRepasses,
    layout: "/freedom"
  },

  {
    path: "/alterarboleto/:contrato_id/:parcela_id",
    name: "Alterar boleto",
    component: AlterarBoleto,
    layout: "/freedom"
  },

  {
    path: "/pessoas",
    name: "Gerenciar Pessoas",
    component: Pessoas,
    layout: "/freedom"
  },

  {
    path: "/demonstrativolocador/:identificador",
    name: "Demonstrativo do Locador",
    component: DemonstrativoLocador,
    layout: "/freedom"
  },
  {
    path: "/demonstrativolocadorcobranca/:identificador",
    name: "Demonstrativo do Locador",
    component: DemonstrativoLocadorCobranca,
    layout: "/freedom"
  },

  {
    path: "/alterarparcela/:contrato_id/:parcela_id",
    name: "Alterar parcela",
    component: AlterarParcela,
    layout: "/freedom"
  },

  {
    path: "/cadastrarcontrato",
    name: "Cadastrar contrato",
    component: CadastrarContrato,
    layout: "/freedom"
  },

  {
    path: "/alterarcontrato/:id",
    name: "Alterar Contrato",
    component: AlterarContrato,
    layout: "/freedom"
  },

  {
    path: "/relatorioinadimplencia",
    name: "Relatório Inadimplência",
    component: RelatorioInadimplencia,
    layout: "/freedom"
  },

  {
    path: "/relatoriorescisao",
    name: "Relatório Rescisão",
    component: RelatorioRescisaoPage,
    layout: "/freedom"
  },

  {
    path: '/relatorioativos',
    name: 'Relatório Ativos',
    component: RelatorioAtivosPage,
    layout: '/freedom'
  },

  {
    path: "/relatorioinadimplenciapdf/:identificador",
    name: "Relatório Inadimplência",
    component: RelatorioInadimplenciaPdf,
    layout: "/freedom"
  },

  {
    path: "/relatoriocontratospdf/:identificador",
    name: "Relatório Contratos",
    component: RelatorioContratosPdf,
    layout: "/freedom"
  },

  {
    path: "/relatorioextratoimob/:identificador",
    name: "Relatório Extrato Imobiliário",
    component: RelatorioExtratoImob,
    layout: "/freedom"
  },

  {
    path: "/relatoriorepassespdf/:identificador",
    name: "Relatório Repasses",
    component: RelatorioRepassePdf,
    layout: "/freedom"
  },
  {
    path: "/relatoriogarantiaspdf/:identificador",
    name: "Relatório Garantias",
    component: RelatorioGarantiasPdf,
    layout: "/freedom"
  },

  {
    path: "/relatorioseguropdf/:identificador",
    name: "Relatório Seguros",
    component: RelatorioSeguroPdf,
    layout: "/freedom"
  },

  {
    path: '/relatoriorescisaopdf/:identificador',
    name: 'Relatório Rescisão',
    component: RelatorioRescisaoPdf,
    layout: '/freedom'
  },

  {
    path: '/relatorioativospdf/:identificador',
    name: 'Relatório Ativos',
    component: RelatorioAtivosPdf,
    layout: '/freedom'
  },

  {
    path: "/nfse",
    name: "Nota fiscal eletronica",
    component: Nfse,
    layout: "/freedom"
  },

  {
    path: "/relatorioseguro",
    name: "Relatório Seguro",
    component: RelatorioSeguroPage,
    layout: "/freedom"
  },

  {
    path: "/gerartransferencias",
    name: "Gerar transferência",
    component: GerarTransferencias,
    layout: "/freedom"
  },

  {
    path: "/relatoriotransferencia",
    name: "Relatório de transferência",
    component: RelatorioTransferenciaPage,
    layout: "/freedom"
  },

  {
    path: "/relatoriogarantias",
    name: "Relatório de garantias",
    component: RelatorioGarantiasPage,
    layout: "/freedom"
  },

  {
    path: "/relatoriorepasse",
    name: "Relatório de repasse",
    component: RelatorioRepassePage,
    layout: "/freedom"
  },

  {
    path: "/imprimirrelatoriorepasse/:data",
    name: "Imprimir relatório de repasse",
    component: ImprimirRelatorioRepassePage,
    layout: "/freedom"
  },
  {
    path: "/repassegarantido",
    name: "Repasse Garantido",
    component: RepasseGarantido,
    layout: "/freedom"
  },
  {
    path: "/historicorepassegarantido",
    name: "Histórico de Repasse Garantido",
    component: HistoricoRepasseGarantido,
    layout: "/freedom"
  },
];

export default routes;
