import React, { useEffect, useState } from "react";
import moment from "moment";

import { api_freedom } from "../services/apis"

const nomeMeses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

const ModalEditarDimob = (props) => {

  const [meses, setMeses] = useState([]);
  const [dimob, setDimob] = useState([]);
  const [mensagem, setMensagem] = useState("");
  const [loading, setLoading] = useState(false);
  const GetDimob = async () => {
		try{

      setLoading(true);
      setMensagem('Carregando...')

			let dimob = await api_freedom.get(`/financeiro/dimob/${props.data.imobiliaria_id ? props.data.imobiliaria_id : 53}/${props.data.contrato_id}/${props.data.pessoa_id}/${props.data.ano}`, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
			if(dimob.data.data.indexOf('TOKEN') === -1){
        let arrMeses = [];

        if(dimob.data.data.length === 0){
          setMensagem('Nenhum dado encontrado.')
        }
        for(let i = 1; i <= 12; i++){
          const dados = dimob.data.data.filter((x) => {return x.mes === i})[0]
          if(dados){
            arrMeses.push(dados);
          } else{
            arrMeses.push({
              id: i + 'new',
              novo_cadastro: true,
              imobiliaria_id: props.data.imobiliaria_id,
              contrato_id: props.data.contrato_id,
              pessoa_id: props.data.pessoa_id,
              ano: Number(props.data.ano),
              mes: i,
              rendimento_bruto: '0.00',
              valor_comissao: '0.00',
              imposto_retido: '0.00',
              disabled: false
            });   
          }
        }

        setDimob(dimob.data.data)
        setMeses(arrMeses)
        // setTotal(total)
			}

      setLoading(false);

		}
		catch(error){
			console.log(error.message)
      setLoading(false);
      setMensagem('Falha ao carregar os dados.')
		}
	}
  const UpdateDimob = async () => {
    try {
      setLoading(true);
      setMensagem("Carregando...");
  
      const dimobsAtualizados = meses.flatMap((item) => {
        if (item.id.length > 1) {
          const qtdIds = item.id.length;
          return item.id.map((singleId) => ({
            ...item,
            id: singleId,
            rendimento_bruto: (Number(item.rendimento_bruto) / qtdIds).toFixed(2),
            valor_comissao: (Number(item.valor_comissao) / qtdIds).toFixed(2),
            imposto_retido: (Number(item.imposto_retido) / qtdIds).toFixed(2),
          }));
        } else {
          return { ...item, id: item.id[0] };
        }
      });
  
      await api_freedom.post(
        `/financeiro/dimob/atualizar`,
        { dimobs: dimobsAtualizados },
        { headers: { authorization: localStorage.getItem("autenticado_freedom") } }
      );
  
      setLoading(false);
      GetDimob();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setMensagem("Falha ao carregar os dados.");
    }
  };
  
  const inputChange = async (e, item) => {
    const { value, name } = e.target;
    const newValue = value.replace(/,/g, '.');

    const mesesUpdated = meses.map(element => {
        const isSameItem = item.ids ? item.ids.includes(element.id.toString()) : element.id === item.id;

        return isSameItem ? { ...element, [name]: newValue } : element;
    });

    setMeses(mesesUpdated);
};


  useEffect(() => {
    GetDimob();
  }, []);

  return (
    <>
      <div className="content-modal" id="modal" style={{ display: "flex" }}>
        <div className="modal">
          <div className="modal-head d-flex justify-content-between">
            <div>
              Editar Dimob
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-scroll">

            <div className="mb-5">
              <label><strong>Locador: </strong>{props.data.locador}</label><br/>              
              <label><strong>Contrato: </strong>{props.data.contrato_id}</label>              
            </div>

            <div className='table'>
              <table className='table'>
                  <thead>
                      <tr>
                          <th>
                              <div class="thead__item">
                                  <label>Mês</label>
                              </div>
                          </th>
                          <th>
                              <div class="thead__item">
                                  <label>Rendimento Bruto</label>
                              </div>
                          </th>
                          <th>
                              <div class="thead__item">
                                  <label>Valor Comissão</label>
                              </div>
                          </th>
                          <th>
                              <div class="thead__item">
                                  <label>Imposto Retido</label>
                              </div>
                          </th>
                      </tr>
                  </thead>
                  <tbody>

                    {loading || dimob.length === 0 ? (
                        <tr><th className='text-center' colSpan='12'>{mensagem}</th></tr>
                    ) : (
                    meses.map((item,index) => (
                      <>
                        <tr>
                          <td className="tbody__text"><p>{nomeMeses[index]}</p></td>
                          <td className="tbody__text">
                            <input type='text' name="rendimento_bruto" value={item.rendimento_bruto} onChange={(event) => {inputChange(event, item)}} disabled={item.disabled ? item.disabled : false}/>
                          </td>
                          <td className="tbody__text">
                            <input type='text' name="valor_comissao" value={item.valor_comissao} onChange={(event) => {inputChange(event, item)}} disabled={item.disabled ? item.disabled : false}/>
                          </td>
                          <td className="tbody__text">
                            <input type='text' name="imposto_retido" value={item.imposto_retido} onChange={(event) => {inputChange(event, item)}} disabled={item.disabled ? item.disabled : false}/>
                          </td>
                        </tr>
                      </>
                    )))}

                  </tbody>
              </table>
            </div>



            </div>
          </div>
          <div className="modal-foot">
            <button
              className="button btn__primary--outline"
              onClick={props.fechar}
            >
              Fechar
            </button>
            <button
              className="button btn__primary"
              onClick={() => UpdateDimob()}
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditarDimob;
