
import React from "react"
import { api_freedom } from '../services/apis'
import jwt_decode from 'jwt-decode'
import PrintButton from "../components/PrintButton";
import moment from "moment";

class DemonstrativoPagamento extends React.Component {

    constructor(props) {
        super(props)

        console.log('====================================');
        console.log(jwt_decode(props.match.params.identificador));
        console.log('====================================');

        try {
            this.state = {
                contrato: {},
                parcelas: [],
                descricao_parcela: [],
                mensagem: 'Carregando ....',
                valor_boleto: 0,
                informacoes: jwt_decode(props.match.params.identificador)
            }
        }
        catch (erro) {
            this.state = {
                contrato: {},
                descricao_parcela: [],
                mensagem: 'Carregando ....',
                valor_boleto: 0
            }
            console.log(erro.message)
            alert('LINK é inválido')
        }
    }

    componentDidMount() {
        this.contrato()
        this.cobrancas()
    }

    contrato = async () => {
        try {
            let contrato = await api_freedom.get(`/demonstrativo/contrato/detalhe/${this.state.informacoes.contrato_id}/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (contrato.data.data.indexOf('TOKEN') === -1) {
                this.setState({ contrato: contrato.data.data[0] })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    cobrancas = async () => {
        try {
            let parcelas = await api_freedom.get(`/demonstrativo/financeiro/idcontrato/${this.state.informacoes.contrato_id}/${this.state.informacoes.imobiliaria_id ? this.state.informacoes.imobiliaria_id : 53}`, { 
                headers: { "authorization": localStorage.getItem('autenticado_freedom') }
            });
            
            if (parcelas.data.data.indexOf('TOKEN') === -1) {
                var parcela = parcelas.data.data.filter((p) => {
                    return p.id === parseInt(this.state.informacoes.financeiro_id) && p.servico_id !== 50 && p.cobranca !== 1;
                })[0];
    
                this.setState({ parcelas: parcela });
    
                var descricao_parcela = parcelas.data.data.filter((p) => {
                    return p.mes_ano === parcela.mes_ano && p.gerar_boleto === 1 && p.servico_id !== 50 && p.cobranca !== 1; 
                });
    
                var valor_boleto = 0;
                descricao_parcela.map((dp) => {
                    if (dp.credito_debito === 1) {
                        valor_boleto += dp.valor;
                    } else {
                        valor_boleto -= dp.valor;
                    }
                    return null;
                });
    
                this.setState({
                    descricao_parcela: descricao_parcela,
                    mensagem: 'Nenhum registro encontrado.',
                    valor_boleto: valor_boleto
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    

    listarDescricaoParcela = (record, index) => {
        return (
            <tr key={index}>
                <td>{record.referencia ? record.referencia : record.nome}</td>
                <td style={{ textAlign: 'right' }}>{record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
            </tr>
        )
    }

    render() {
        console.log(this.state.parcelas)
        return (
            <>
                <div className='demonstrativo'>
                    <div className="container">
                        <header className='header_demonstrativo' style={{display: 'flex', alignItems: 'flex-start'}}>
                            <div className="menu__img" style={{display: 'flex', paddingTop: 15}}>
                                <img style={{paddingRight: 5}} src={require('../assets/imgs/mobilelogo.png')} alt="" />
                                <img src={require('../assets/imgs/epar_texto.png')} class="nav__max__img" alt="" />
                            </div>
                            <div className="docs-head" style={{width: '100%'}}>
                                <h2 style={{ color: '#76777C', fontFamily: 'Dosis, sans-serif' }}>Recibo de Aluguel</h2>
                                <p>Locatário(a): <span>{this.state.contrato.pessoas && this.state.contrato.pessoas.filter((p) => {
                                    return p.tipo === 'Locatário'
                                })[0].nome}</span></p>
                                <p>Data de Pagamento: {this.state.parcelas.pago ? moment(this.state.parcelas.pago).format('DD/MM/YYYY') : 'Boleto ainda não foi pago'}</p>
                                <p>Este recibo não quita débitos anteriores*</p>
                            </div>

                        </header>
                        <section>
                            <table className="table table_demonstrativo">
                                <thead>
                                    <tr>
                                        <th scope="col">Descrição</th>
                                        <th style={{ textAlign: 'right' }} scope="col">Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.descricao_parcela.filter((dp) => {
                                        return dp.credito_debito === 1
                                    }).map(this.listarDescricaoParcela)}
                                    {this.state.descricao_parcela.filter((dp) => {
                                        return dp.credito_debito === 1
                                    }).length === 0 && <tr><td colSpan='2'>Sem descrições</td></tr>}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th scope="col">Descontos</th>
                                        <th style={{ textAlign: 'right' }} scope="col">Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.descricao_parcela.filter((dp) => {
                                        return dp.credito_debito === 0 && dp.nome !== 'Repasse aluguel'
                                    }).map(this.listarDescricaoParcela)}
                                    {this.state.descricao_parcela.filter((dp) => {
                                        return dp.credito_debito === 0
                                    }).length === 0 && <tr><td colSpan='2'>Sem descontos</td></tr>}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th scope="col">Valor total do boleto</th>
                                        <th style={{ textAlign: 'right' }} scope="col">{this.state.valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                                    </tr>
                                </thead>
                            </table>
                        </section>
                        <PrintButton />
                    </div>
                </div>

            </>
        );
    }
}

export default DemonstrativoPagamento;
