import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { api_admin } from "../services/apis";
import jwtDecode from "jwt-decode";
import { requestUsersAccessList } from "../redux/real-state-slice";
import logo from "../assets/imgs/logo.png";
import chat from "../assets/imgs/chat.png";
import fundo from "../assets/imgs/fundo.jpg";

const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const dispatch = useDispatch();
  const navigate = useHistory();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const login = async () => {
    if (!usuario || !senha) {
      alert("Necessário informar usuário e senha para acessar o sistema.");
      return;
    }
    
    try {
      const response = await api_admin.post("/imobon/loginfreedom", {
        usuario,
        senha,
        sistema: 6,
      });
      if (!response.data.token.includes("Dados inválidos")) {
        localStorage.setItem("autenticado_freedom", response.data.token);
        const dadosUser = jwtDecode(response.data.token)
        dispatch(requestUsersAccessList(dadosUser));
        navigate.push("/selecionar/imobiliaria");
      } else {
        alert(response.data.token);
      }
    } catch (error) {
      console.error("Erro no login:", error.message);
    }
  };

  return (
    <section className="fxt-template-animation fxt-template-layout19">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 fxt-bg-color">
            <div className="fxt-content">
              <div className="fxt-header-esquerda">
                <img src={logo} alt="Logo" />
              </div>
              <div className="fxt-form">
                <h2>Acesso</h2>
                <p className="subtitulo" style={{ marginBottom: "50px" }}>
                  Digite seus dados abaixo
                </p>
                <div className="form-group">
                  <div className="titulos-entrada">E-mail ou Usuário</div>
                  <input
                    style={{ fontSize: "1em" }}
                    type="text"
                    value={usuario}
                    className="form-control"
                    name="usuario"
                    placeholder=""
                    required
                    onChange={(e) => setUsuario(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="fxt-checkbox-area">
                    <a href="/" className="switcher-text2">
                      Esqueceu sua senha?
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <div className="titulos-entrada">Senha</div>
                  <input
                    style={{ fontSize: "1em" }}
                    type="password"
                    value={senha}
                    className="form-control"
                    name="password"
                    required
                    onChange={(e) => setSenha(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && login()}
                  />
                </div>
                <div className="form-group text-center">
                  <button className="btn__freedom btn--active" onClick={login}>
                    Entrar
                  </button>
                </div>
              </div>
            </div>
            <div className="chat-esquerda">
              <img src={chat} alt="Chat" />
            </div>
          </div>
          <div className="col-md-6 fxt-bg-img" style={{ backgroundImage: `url(${fundo})` }}>
            <div className="fxt-header-rodape">
              <p className="texto-rodape">
                <b>Epar Gestão de Contratos © 2020</b>
                <br />
                Todos os Direitos Reservados - Tel: (31) 3615-4004
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;