
  import React, { useEffect, useState } from "react";
  import ReactDOM from "react-dom";
  import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

  import Login from "./layouts/Login";
  import Sistema from "./layouts/Sistema";
  import Imprimir from "./layouts/Imprimir";

  import 'bootstrap/dist/css/bootstrap.min.css';
  import './assets/styles/style.css';
  import './assets/styles/new_style.css';

  import './assets/styles/cards.css';
  import './assets/styles/modal.css';
  import './assets/styles/remaster-style.css';
  import './assets/styles/table.css';
  import './assets/styles/tags.css';

  import './assets/font/fontawesome-free-5.13.0-web/css/all.min.css';
  import './assets/font/font-samsungsharpsans/samsungsharpsans_1.woff';
  import './assets/font/font-samsungsharpsans/samsungsharpsans-bold_1.woff';
  import './assets/font/font-samsungsharpsans/samsungsharpsans-medium_1.woff';
  import './assets/font/font-dosis/font-dosis.css';
  import './assets/font/font-poppins/poppins.css';
  import { Provider, useSelector } from "react-redux";
  import { store } from "./redux/store";
  import SelecionarImobiliaria from "./layouts/SelecionarImobiliaria";
  import { requestUsersAccessList, selectImobiliaria } from "./redux/real-state-slice";
  import { useAppDispatch } from "./hooks/redux-hooks";
  import jwtDecode from "jwt-decode";

  const App = () => {
    const dispatch = useAppDispatch();
    const imobiliariaSelecionada = useSelector(selectImobiliaria);
    const [dadosUsuario, setDadosUsuario] = useState(null);
    const token = localStorage.getItem("autenticado_freedom");

    useEffect(() => {
      if (!token) {
        return;
      }

      try {
        const dadosUser = jwtDecode(token);
        setDadosUsuario(dadosUser);
        if (dadosUser) {
          dispatch(requestUsersAccessList(dadosUser));
        }
      } catch (error) {
        return;
      }
    }, [dispatch, token]);
    

    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/imprimir" component={Imprimir} />
            <Route path="/freedom" component={Sistema} />
            <Route path="/selecionar/imobiliaria" component={SelecionarImobiliaria} />
            <Route path="/" component={Login} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  };

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );