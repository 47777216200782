
import React from "react"
import { api_freedom } from '../services/apis'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

class DetalheParcela extends React.Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('autenticado_freedom')) {
            props.history.push('/')
        }

        this.state = {
            contrato: [],
            servico: [],
            descricao_parcela: [],
            mensagem: 'Carregando ....',
            total_cobrancas: 0,
            modal_titulo: '',
            modal_corpo: '',
            modal_botoes: '',
            modal: false,
            modal_titulo_excluir: '',
            modal_corpo_excluir: '',
            modal_botoes_excluir: '',
            modal_excluir: false,
            servicoId: '',
            referencia: '',
            descricao: '',
            data: '',
            valor: '',
            id: '',
            display: false,
            anteriores: [],
            mesAnterio: '',
            mesAtual: '',
            dataVencimentoBoleto: ''
        }

    }

    componentDidMount() {
        this.contrato()
        this.cobrancas()
        this.servicos()
    }

    disable_button_master() {
        return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "Read"
    }


    disable_button() {
        return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read"
    }

    contrato = async () => {
        try {
            let contrato = await api_freedom.get(`/contrato/detalhe/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
            if (contrato.data.data.indexOf('TOKEN') === -1) {
                this.setState({ contrato: contrato.data.data[0] })
                if (jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id !== contrato.data.data[0].imobiliaria_id) {
                    this.props.history.push('/freedom/contratos')
                }
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    servicos = async () => {
        let servico = await api_freedom.get(`/servico/all`, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })
        if (servico.data.data.indexOf('TOKEN') === -1) {
            this.setState({ servico: servico.data.data })
        }
    }

    cobrancas = async () => {
        try {
            let parcelas = await api_freedom.get(`/financeiro/idcontrato/${this.props.match.params.contrato_id}/${jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id}`, { 
                headers: { "authorization": localStorage.getItem('autenticado_freedom') }
            });
    
            if (parcelas.data.data.indexOf('TOKEN') === -1) {
                let parcela = parcelas.data.data.filter((p) => {
                    return p.id === parseInt(this.props.match.params.parcela_id) && p.servico_id !== 51
                })[0];
    
                let descricao_parcela = parcelas.data.data.filter((p) => {
                    return p.mes_ano === parcela.mes_ano && p.servico_id !== 50 && p.cobranca !== 1 && p.servico_id !== 51;
                });
    
                let dataVencimentoBoleto = parcelas.data.data.find(y => y.servico_id == 1 && y.mes_ano === parcela.mes_ano && y.servico_id !== 50 && y.cobranca !== 1 && y.servico_id !== 51);
    
                this.setState({
                    descricao_parcela: descricao_parcela,
                    mensagem: 'Nenhum registro encontrado.',
                    total_cobrancas: descricao_parcela.length,
                    dataVencimentoBoleto: dataVencimentoBoleto ? moment(dataVencimentoBoleto.data_vencimento).format("YYYY-MM-DD") : null
                });
    
                this.servicosAnterior(parcela.mes_ano);
            }
        }
        catch (error) {
            console.log(error.message);
        }
    }
    
    servicosAnterior = async (mes) => {
        let d = moment(mes + "-01").subtract(1, 'd');
    
        let servicos = await api_freedom.post(`/financeiro/contratoservicomes`, {
            imobiliaria_id: jwt_decode(localStorage.getItem('autenticado_freedom')).imobiliaria_id,
            contrato_id: this.props.match.params.contrato_id,
            mes_ano: d.format("YYYY-MM")
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } });
    
        if (servicos.data.data.indexOf('TOKEN') === -1) {
            console.log(servicos.data.data)
            const servicosFiltrados = servicos.data.data.filter(servico => servico.servico_id !== 50);
    
            this.setState({
                anteriores: servicosFiltrados, 
                mesAnterio: d.format("YYYY-MM"),
                mesAtual: mes,
            });
        }
    }
    
    listarDescricao = (record, index) => {
        var valor_boleto = 0

        if (record.servico_id == 19 && (this.state.contrato.cobrar_ted == 0)) {
            return null
        }

        this.state.descricao_parcela.map((d, i) => {
            if (i <= index) {
                if (parseInt(d.servico_id) === 19 && (this.state.contrato.cobrar_ted == 0)) {
                    return null
                }

                if (parseInt(d.credito_debito) === 1) {
                    valor_boleto += parseFloat(d.valor)
                }
                else {
                    valor_boleto -= parseFloat(d.valor)
                }
            }
            return null
        })


        return (
            <tr key={index}>
                <td>{moment(record.data_vencimento).utc(false).format('DD/MM/YYYY')}</td>
                <td>{record.plano_contas}</td>
                <td>{record.servico_id}</td>
                <td>
                    {record.referencia ? record.referencia : `${record.nome} ${record.pessoa_id ? ` para ${record.pessoa_nome} - ${record.cpf_cnpj}` : ''}`}
                    {
                        record.receipt_url_pago && record.payment_date ? (
                            <button className='btn__freedom btn--normal' style={{ marginTop: '-1px', width: '30px', height: '23px', marginLeft: '5px' }}
                                onClick={() => {
                                    window.open(record.receipt_url_pago, '_blank')
                                }}
                            >
                                <i className='fas fa-eye' />
                            </button>
                        ) : (null)
                    }
                </td>
                <td>{this.props.match.params.contrato_id}</td>
                <td>{record.credito_debito === 1 ? record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                <td style={{ color: 'red' }}>{record.credito_debito === 0 ? `-${record.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : ''}</td>
                <td>{valor_boleto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                <td><button hidden={this.disable_button()}className="button btn__primary--outline" onClick={() => this.modalOpen(record.id, record.servico_id, record.referencia, record.data_vencimento, record.valor)}><i className='fas fa-pen'/></button></td>
                {parseInt(record.servico_id) !== 2  && parseInt(record.servico_id) !== 17 && parseInt(record.servico_id) !== 19 ? (
                    <td><button hidden={this.disable_button()} className="button btn__primary--outline"
                        onClick={() => {
                            this.openModalExcluir(record)
                        }} 
                    >Deletar</button>
                    </td>
                ) : <td></td>}
            </tr>
        )
    }

    modalOpen = (id, servico, referencia, data, valor) => {
        this.setState({
            display: true,
            id: id,
            modal_titulo: 'Editar Serviços',
            servicoId: servico,
            referencia: referencia,
            data: moment(data).format('YYYY-MM-DD'),
            valor: valor,
            modal_botoes: <>
                <button className='button btn__primary--outline' onClick={() => { this.editarServicos() }}>Alterar</button>
                <button className='button btn__primary' onClick={() => { this.setState({ display: false, servicoId: '', referencia: '' }) }}>Cancelar</button>
            </>
        })
    }

    openModalExcluir = (line) =>{
        this.setState({
            modal_excluir: true, 
            id: line.id, 
            mes_ano: line.mes_ano, 
            contrato_id: line.contrato_id, 
            imobiliaria_id: line.imobiliaria_id,
            modal_titulo_excluir: 'Excluir serviço',
            modal_body_excluir: `Deseja realmente Excluir o serviço - ${line.referencia ? line.referencia : line.nome} ?`,
            modal_foot_excluir: <><button className="btn__freedom btn--active" onClick={() => this.deletarServico(line)}>Excluir</button>
            <button className="btn__freedom" onClick={() => this.setState({modal_excluir: false})}>Cancelar</button></>
        })
    }

    editarServicos = async () => {
        try {
            await api_freedom.post(`/financeiro/update/servico`,
                {
                    servico_id: this.state.servicoId,
                    referencia: this.state.referencia,
                    data_vencimento: moment(this.state.data).format('YYYY-MM-DD'),
                    valor: this.state.valor,
                    id: this.state.id
                }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

            this.setState(this.setState({ display: false, servicoId: '', referencia: '' }))
            this.cobrancas();
        } catch {

        }
    }

    deletarServico = async (servico) => {
        let deletado = await api_freedom.post('/financeiro/delete/servico', {
            financeiro_id: servico.id,
            contrato_id: servico.contrato_id, 
            imobiliaria_id: servico.imobiliaria_id
        }, {headers: {"authorization": localStorage.getItem('autenticado_freedom')}})
    
        console.log(deletado.data.data)
        if (!deletado.data.data) {
            alert('Não é possivel deletar serviços com boletos vinculados')
        } else {
            this.cobrancas();
            this.setState({ modal_excluir: false })
            alert('Serviço deletado com sucesso')

        }
      }

    changeServico = async (index, v) => {
        this.state.anteriores[index].valor = v
        this.setState({anteriores: this.state.anteriores})
    }

    changeServicoMarked = async (index, v) => {
        this.state.anteriores[index].marked = v
        this.setState({anteriores: this.state.anteriores})
    }

    cloneMonth = async () => {
        await api_freedom.post(`/financeiro/cloneservico`, {
            servicos: this.state.anteriores,
            mes_ano: this.state.mesAtual,
            dataVencimentoBoleto: this.state.dataVencimentoBoleto
        }, { headers: { "authorization": localStorage.getItem('autenticado_freedom') } })

        this.setState({
            modal: false
        })

        this.contrato()
        this.cobrancas()
        this.servicos()
    }

    render() {
        return (
            <>
                <div className="content-modal" style={{ display: this.state.modal ? 'flex' : 'none' }}>
                    <div className="modal">
                        <div className="modal-head">
                            Clonar serviços do mês anterior
                        </div>
                        <div className="modal-body">
                            <div className="modal-scroll">
                                <div style={{marginTop: '1%'}}>
                                    {
                                        this.state.anteriores.map((e, index) => (
                                            <div className="d-flex align-items-center">
                                                <label className="mb-0 checkbox-label">
                                                    <input type="checkbox" checked={e.marked} onChange={a => this.changeServicoMarked(index, a.target.checked)} />
                                                    <span className="checkbox-custom rectangular"></span>
                                                </label>

                                                <div className="grupo__campo ml-4">
                                                    <label style={{width: '100%'}}>{e.nome}: </label><br/>
                                                    <div className="grupo__input">
                                                        <input type="number" step="0.01" defaultValue={e.valor} onChange={a => this.changeServico(index, a.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-foot">
                            <button  className='btn__primary button' onClick={a => this.setState({modal: false})}>Fechar</button>
                            {
                                this.disable_button_master() ? (
                                    <button className='btn__primary button' onClick={this.cloneMonth}>Salvar</button>
                                ) : (null)
                            }
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.descricao_parcela.length > 0 &&
                        <div className="d-flex justify-content-between align-items-center">
                            <label>Total de cobranças: {this.state.total_cobrancas}</label>
                            {
                                this.disable_button_master() ? (
                                    <button className="btn__primary button" onClick={a => this.setState({ modal: true })}>Clonar mês anterior</button>
                                ) : (null)
                            }
                        </div>
                    }
                    <div className='table-responsive'>
                        <table className='table' style={{ marginBottom: '50px' }}>
                            <thead>
                                <tr>
                                    
                                    <th>
                                        <div className="thead__item">
                                            <label>Data</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>Plano de contas</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>ID Serviço</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>Histórico / Descrição</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label># Contrato</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>Crédito</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>Débito</label>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="thead__item">
                                            <label>Saldo</label>
                                        </div>
                                    </th>
                                    <th colSpan={2}>
                                        <div className="thead__item" hidden={this.disable_button()}>
                                            <label>Ação</label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.descricao_parcela.length === 0 && <tr><th className='text-center' colSpan='8'>{this.state.mensagem}</th></tr>}
                                {this.state.descricao_parcela.map(this.listarDescricao)}
                            </tbody>
                        </table>
                    </div>

                    <div className="content-modal" style={{display: this.state.modal_excluir ? 'flex' : 'none'}}>
                        <div className="modal" style={{width:"auto"}}>
                            <div className="modal-head">
                                {this.state.modal_titulo_excluir}
                            </div>
                            <div className="modal-body">
                                <div className='text-center' style={{padding: '2vw', fontSize: '1.1em'}}>
                                    {this.state.modal_body_excluir}
                                </div>
                            </div>
                            <div className="modal-foot">
                                <div className="btns">
                                    {this.state.modal_foot_excluir}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-modal" id="modal" style={{ display: this.state.display ? 'flex' : 'none' }}>
                        <div className="modal">
                            <div className="modal-head">
                                {this.state.modal_titulo}
                            </div>
                            <div className="modal-body">
                                <div className='modal-scroll'>
                                    <div className='grupo__campo'>
                                        <label style={{ width: '100%' }}>Serviço ID</label><br />
                                        <div className='grupo__campo__form'>
                                            <select value={this.state.servicoId} onChange={e => this.setState({ servicoId: e.target.value })}>
                                                {this.state.servico.map(se => (
                                                    <option value={se.id}>{se.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='grupo__campo'>
                                        <label style={{ width: '100%' }}>Histórico/Descrição: </label><br />
                                        <div className='grupo__campo__form' >
                                            <input type='text' value={this.state.referencia} onChange={(event) => {
                                                this.setState({ referencia: event.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className='grupo__campo'>
                                        <label style={{ width: '100%' }}>Data: </label><br />
                                        <div className='grupo__campo__form' >
                                            <input type='date' value={this.state.data} onChange={(event) => {
                                                this.setState({ data: event.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className='grupo__campo'>
                                        <label style={{ width: '100%' }}>Valor: </label><br />
                                        <div className='grupo__campo__form' >
                                            <input type='text' value={this.state.valor} onChange={(event) => {
                                                this.setState({ valor: event.target.value })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-foot">
                                {this.state.modal_botoes}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DetalheParcela;
