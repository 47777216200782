import React from 'react';
import { Box, Container, Stack, useTheme } from '@mui/material';

const MiniCard = (props) => {
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 3em)',
      }}
    >
      <Box
        p={5}
        width={'60em'}
        height={'fit-content'}
        borderRadius={2}
        boxShadow={'rgba(0, 0, 0, 0.35) 0px 5px 15px'}
      >
        <Stack direction={'column'} gap={3}>
          {props.children}
        </Stack>
      </Box>
    </Container>
  );
};

export default MiniCard;
