import React, { useEffect, useState } from "react";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import routes from "../routes.js";
import Menu from "../components/Menu";
import jwt_decode from 'jwt-decode';
import { occupationImobiliariaActions } from "../redux/real-state-slice.jsx";
import { useAppDispatch } from "../hooks/redux-hooks.jsx";

const Sistema = () => {
  const [dados, setDados] = useState({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    try {
      const userData = jwt_decode(localStorage.getItem("autenticado_freedom"));
      setDados(userData || {});
    } catch (error) {
      console.error("Erro ao decodificar o JWT:", error.message);
    }
  }, []);

  const handleLogoutv2 = () => {
    dispatch(occupationImobiliariaActions.clearImobiliaria());
  };

  const handleLogout = () => {
    localStorage.removeItem("autenticado_freedom");
    handleLogoutv2();
    history.push("/");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/freedom") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            name={prop.name}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {Object.keys(dados).length > 0 && <Menu />}
      <div className="box__content">
        <div className="box__header">
          <p>{dados.imobiliaria}</p>
          <div>
            <p>{dados.email}</p>
            <Link className="out" to="/selecionar/imobiliaria"
            >
              <i className="fas fa-sign-out-alt"></i>
              <p>Trocar Imobiliária</p>
            </Link>
            <Link className="out" to="/" onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt"></i>
              <p>Sair</p>
            </Link>
          </div>
        </div>
        <div className="box__body">
          <Switch>{getRoutes(routes)}</Switch>
        </div>
        <p>{process.env.REACT_APP_VERSION}</p>
      </div>
    </>
  );
};

export default Sistema;
